html {
  font-size: 14px;
  scrollbar-color: rgba(207, 207, 207, 0.3) transparent;
}

body {
  color: var(--on-surface);
  background-color: var(--surface);
  font-family: 'Space Grotesk', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: 16px; */
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth; 
  margin: 0 auto;
}

div {
  box-sizing: border-box;
  /* user-select: auto !important;
  -webkit-user-select: auto !important; */
  color: var(--on-surface);
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

[contentEditable=true]:empty:not(:focus):before{
  content:attr(placeholder);
  color: var(--on-surface-variant);
}

[contentEditable="true"]:empty:before {
  content: attr(placeholder);
  color: var(--on-surface-variant);
}

input {
  font-family: 'Space Grotesk', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  vertical-align: top;
  text-align: left;
}

textarea {
  font-family: 'Space Grotesk', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  vertical-align: top;
  text-align: left;
}


a {
  text-decoration: underline;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('./resources/fonts/SpaceGrotesk-VariableFont_wght.ttf')
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  /* opacity: 1; */
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}